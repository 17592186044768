import React, {useState} from "react"
import {X} from "react-bootstrap-icons"
import styles from "../../bikeDetails/bikeDetails.module.scss"
import {ErrorMessage, Field, Form, Formik} from "formik"
import * as Yup from "yup"
import {useTranslation} from "next-i18next"
import Modal from "../../reusable/components/Modal/Modal"
import Typography from "../../reusable/Typography/Typography"
import Button from "../../reusable/components/Button"
import axios from "axios"

type Values = {
	email: string
	name:string
}

type Props = {
	closeModal: () => void
	isModalOpen: boolean
	eBook: any
}

function DownloadEbookModal({closeModal, isModalOpen, eBook}: Props) {
	const downloadEbookForm = (isMobile?:boolean) => {
		return (<Formik
			initialValues={{
				email: "",
				name: ""
			}}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{({touched, errors}) => (
				<Form className={`d-flex ${isMobile ? "container col-12" : ""} flex-column align-items-start`}
					style={{paddingTop: "40px"}}>
					{ error ? <Typography variant={"bodySmBold"}
						className="text-danger text-left">{t("something-went-wrong")}</Typography> : null}
					<div style={{marginBottom: "2rem", width: "100%"}}>
						<Typography variant={"bodySmBoldMdBodyLgBold"}>
							{t("name-label")} <span className="text-danger"
								style={{fontSize: 20}}>*</span>
						</Typography>
						<Field
							style={{
								width: "100%",
								height: "50px",
								border: touched.name && errors.name ? "2px solid red" : "1px solid #D2D7DC",
								borderRadius: "4px",
								outline: "none",
								padding: "3px",
								marginTop: "2px"
							}}
							id="name"
							name="name"

						/>
						<ErrorMessage name="name"
							component="div"
							className="text-danger small mb-2" />
					</div>
					<div style={{marginBottom: "2rem", width: "100%"}}>
						<Typography variant={"bodySmBoldMdBodyLgBold"}>
							{t("email-label")} <span className="text-danger"
								style={{fontSize: 20}}>*</span>
						</Typography>
						<Field
							style={{
								width: "100%",
								height: "50px",
								border: touched.email && errors.email ? "2px solid red" : "1px solid #D2D7DC",
								borderRadius: "4px",
								outline: "none",
								padding: "3px",
								marginTop: "2px"
							}}
							id="email"
							name="email"
							type="email"
						/>
						<ErrorMessage name="email"
							component="div"
							className="text-danger small" />
					</div>
					<Button
						className={`${isMobile ? "w-100 mb-3" : "w-50 mt-0 "}`}
						label={t("send-me-link")}
						color={"white"}
						type={"submit"}
						fontSizeSmall
						bgColor={"black"}
						textColor={"white"}
						isLoading={isSubmitting}
						spinnerColor={"white"}
					/>
				</Form>)}
		</Formik>)
	}

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const {t} = useTranslation("downloadableAssets")
	const [linkSent, setLinkSent] = useState<boolean>(false)

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.email("Invalid email address")
			.required("Email is required"),
		name: Yup.string()
			.required("Name is required")

	})
	const [error, setError] = useState<boolean>(false)

	const handleSubmit = async (values: Values) => {
		setIsSubmitting(true)

		try {
			const checkContactResponse = await axios.get(`/api/clickfunnel/checkcontact?email=${encodeURIComponent(values.email)}`)
			const existingContacts = checkContactResponse.data
			if (existingContacts.length > 0) {
				const contactId = existingContacts[0].id
				const existingTagIds = existingContacts[0].tags.map(tag => tag.id)
				const updatedTagIdsSet = new Set([...existingTagIds, eBook.tag_id])
				const updatedTagIds = Array.from(updatedTagIdsSet)
				try {
					const updateContactResponse = await axios.put("/api/clickfunnel/updatecontact", {
						contactId,

						tag_ids: updatedTagIds
					})

					if (updateContactResponse.status === 200) {
						setLinkSent(true)
					} else {
						setError(updateContactResponse.data)
					}
				} catch (error) {
					console.error("Error updating contact:", error)
					setError(true)
				}
			} else {
				const createContactOptions = {
					method: "POST",
					url: "/api/clickfunnel/createcontact",
					headers: {
						"Content-Type": "application/json"
					},
					data: {

						email_address: values.email,

						first_name: values.name,

						tag_ids: [eBook.tag_id]
					}
				}

				const createContactResponse = await axios.request(createContactOptions)

				if (createContactResponse.status === 200) {
					setLinkSent(true)
				} else {
					setError(createContactResponse.data)
				}
			}
		} catch (error) {
			console.error("Error handling submit:", error)
			setError(true)
		} finally {
			setIsSubmitting(false)
		}
	}

	const handleModalClose = () => {
		setTimeout(() => {
			setLinkSent(false)
		}, 1000)
		closeModal()
	}

	return (
		<Modal isOpen={isModalOpen}
			closeModal={handleModalClose} >
			<div className={` d-none d-md-flex ${styles.modalWrapper}`}>
				<div className={styles.contentWrapper}>
					<button onClick={handleModalClose}
						className={"d-none d-md-block position-absolute btn bg-white rounded-circle p-0 top-0 end-0"}
						style={{
							marginTop: "5%",
							marginRight: "3%",
							zIndex: 1
						}}>
						<X size={36}
							color={"black"}/>
					</button>
					<Typography variant="heading1"
						style={{fontSize: "36px", marginTop: "10px"}} >{t("modal-title")}</Typography>
					{linkSent ? "" : <Typography variant="bodyLg"
						style={{marginTop: 16}}>{t("modal-description")}</Typography>}
					{linkSent ?
						<div className="d-flex flex-column justify-content-start align-items-start">
							<Typography variant="bodySm"
								style={{fontSize: "15px", marginTop: "1.6rem", marginBottom: "1.6rem"}}>{t("modal-success-message")}</Typography>
						</div> : downloadEbookForm()}
				</div>
			</div>
			{/* End of desktop modal */}
			{/* Start of mobile modal */}
			<div className="slide-up d-block d-md-none d-flex justify-content-start flex-column"
				style={{position: "absolute", bottom: "-30px", left: 0, right: 0, background: "white", borderTopLeftRadius: "2rem", borderTopRightRadius: "2rem", zIndex: 9999999}}>
				<div style={{minHeight: linkSent ? "420px" : "542px"}}
					className=" position-relative d-flex justify-content-center mt-2 align-items-center flex-column">
					<button onClick={handleModalClose}
						className={"d-md-block position-absolute btn bg-white rounded-circle p-0 top-0 end-0"}
						style={{
							marginTop: "5%",
							marginRight: "3%",
							zIndex: 1
						}}>
						<X size={36}
							color={"black"}/>
					</button>
					<div className="container">
						<Typography variant="heading1"
							style={{fontSize: "28px", marginTop: "10px", lineHeight: "36px"}} >{t("modal-title")}</Typography>
						{linkSent ? "" : <Typography variant="bodyLg"
							style={{marginTop: 16, lineHeight: "28px"}}>{t("modal-description")}</Typography>}
					</div>
					{linkSent ?
						<div className="d-flex container flex-column justify-content-start align-items-start">
							<Typography variant="bodySm"
								style={{fontSize: "15px", marginTop: "1.6rem", marginBottom: "1.6rem"}}>{t("modal-success-message")}</Typography>
						</div> :
						<div className="col-12 pb-2 d-flex flex-column justify-content-start align-items-center">
							{downloadEbookForm(true)}
						</div>}

				</div>
			</div>
		</Modal>
	)
}

export default DownloadEbookModal
