import Typography from "../../reusable/Typography/Typography"
import Button from "../../reusable/components/Button"
import React, {useContext, useState} from "react"
import Image from "next/image"
import DownloadEbookModal from "./DownloadEbookModal"
import {Slides} from "../../reusable/components/Slides"
import {useTranslation} from "next-i18next"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"

const DownloadableContentList = [
	{
		id: 1,
		eBookImage: "/assets/images/eBook-one.png",
		eBookTitle: "ebook-title-1",
		eBookDesc: "ebook-desc-1",
		tag: "45 km/h Guide",

		tag_id: 167315
	},
	{
		id: 2,
		eBookImage: "/assets/images/eBook-two.png",
		eBookTitle: "ebook-title-2",
		eBookDesc: "ebook-desc-2",
		tag: "E-Bikes All you need to know",

		tag_id: 167316
	},
	{
		id: 3,
		eBookImage: "/assets/images/eBook-five.png",
		eBookTitle: "ebook-title-3",
		eBookDesc: "ebook-desc-3",
		tag: "New E-Mobility",

		tag_id: 167319
	},
	{
		id: 4,
		eBookImage: "/assets/images/eBook-three.png",
		eBookTitle: "ebook-title-4",
		eBookDesc: "ebook-desc-4",
		tag: "My E-Bike Guide",

		tag_id: 167317
	},
	{
		id: 5,
		eBookImage: "/assets/images/eBook-four.png",
		eBookTitle: "ebook-title-5",
		eBookDesc: "ebook-desc-5",
		tag: "My E-Bike Tours",

		tag_id: 167318
	}
]

type eBookType = {
	id: number
	eBookImage: string
	eBookTitle: string
	eBookDesc: string
	tag: string
	// eslint-disable-next-line
	tag_id:number
}

type Props = {
	isBlogsPage?:boolean
}

const DownloadableContent = ({isBlogsPage = false}:Props) => {
	const [showEbookInfo, setShowEbookInfo] = useState(false)
	const [activeEbook, setActiveEbook] = useState(null)
	const [isFormModalVisible, setIsFormModalVisible] = useState(false)
	const {t} = useTranslation("downloadableAssets")
	const {reactGA} = useContext(AnalyticsContext)

	const onEbookContentHover = (item: object) => {
		setShowEbookInfo(true)
		setActiveEbook(item)
	}

	const handleDownloadBtnClick = (eBook: eBookType) => {
		reactGA?.event({
			category: gaCategories.homePage,
			action: gaEvents.ebookDownloadBtnClicked,
			label: `${gaEvents.ebookDownloadBtnClicked}: ${eBook?.tag}`,
			nonInteraction: false
		})
		setActiveEbook(eBook)
		setIsFormModalVisible(true)
	}

	return (
		<section className={"pt-4 pb-4"}>
			<div className={"container d-none d-md-block"}>
				<div className="row justify-content-center">
					<Typography variant={"heading2Black"}
						className={`text-black-med text-bold ${isBlogsPage ? "text-start" : "text-center"} mb-5`}>
						{t("downloadable-section-title")}</Typography>
					<div className="row d-flex gx-1 justify-content-between">
						{DownloadableContentList?.map(item => {
							return (
								<div className="col-auto"
									key={item.id}>
									<div style={{height: 357, width: 252}}
										className="ebook-container p-3 cursor-pointer"
										onMouseOver={() => onEbookContentHover(item)}
										onMouseLeave={() => setShowEbookInfo(false)}>
										<div className="position-relative"
											style={{width: "100%", height: "100%", borderRadius: 16}}>
											<Image src={item.eBookImage}
												layout={"fill"}
												objectFit={"cover"}
												width={274}
												height={246}
												style={{borderRadius: 16}}/>
											<div className="position-relative h-100 d-flex align-items-end justify-content-center bottom-0 mx-auto"
												style={{zIndex: showEbookInfo ? 10 : "", width: "95%"}}>
												<Button label={t("download-free-text")}
													color="#fff"
													hoverColor="#212529"
													bgColor="#212529"
													textColor="#fff"
													className="col-12 mx-auto mb-3 position-absolute"
													btnFontSize={12}
													style={{zIndex: 10}}
													onClick={() => handleDownloadBtnClick(item)}
												/>
											</div>
											<div
												className="ebook-hover-content text-white position-absolute pt-4 px-3 pb-2"
												style={{opacity: showEbookInfo && activeEbook.id === item.id ? 1 : 0, transition: "opacity 0.3s ease-in-out"}}>
												<Typography variant="bodyLgBold">
													{t(item.eBookTitle)}
												</Typography>
												<Typography variant="bodyLg"
													className="mt-4">
													{t(item.eBookDesc)}
												</Typography>
											</div>
										</div>

									</div>
								</div>
							)
						})
						}
					</div>
				</div>
			</div>
			<div className={"d-block d-md-none justify-content-center"}
				style={{overflowX: "hidden"}}>
				<Typography className="ps-3 col-12"
					variant={"bodyLgBlack"}
					style={{fontSize: 28, marginBottom: 32}}>{t("downloadable-section-title")}</Typography>
				<div className="col-12 ps-3">
					<div className="row gx-3 ">
						<Slides centerMode
							centerModePadding={{left: 0, bottom: 0, right: 25}}>
							{DownloadableContentList.map(item => {
								return (
									<div className="col-12 pe-3"
										key={item.id}>
										<div className="ebook-container p-3">
											<div className="d-flex justify-content-between align-items-center">
												<div style={{width: 80, height: 106}}
													className="position-relative col-auto">
													<Image src={item.eBookImage}
														layout={"fill"}
														objectFit={"contain"}
														width={80}
														height={106}
														style={{borderRadius: 16}}/>
												</div>
												<Typography variant={"heading3MdBodyLgBlack"}
													className="col-7">{t(item.eBookTitle)}</Typography>
											</div>
											<div className="mt-3 mb-4">
												<Typography variant="bodySm">{t(item.eBookDesc)}</Typography>
											</div>
											<div>
												<Button label={t("download-free-text")}
													color="#fff"
													hoverColor="#212529"
													bgColor="#212529"
													textColor="#fff"
													className="w-100 mb-3 "
													btnFontSize={14}
													style={{zIndex: 10}}
													onClick={() => handleDownloadBtnClick(item)}
												/>
											</div>
										</div>
									</div>
								)
							}) }
						</Slides>
					</div>
				</div>
			</div>
			<DownloadEbookModal closeModal={() => setIsFormModalVisible(false)}
				isModalOpen={isFormModalVisible}
				eBook={activeEbook} />
		</section>
	)
}

export default DownloadableContent
